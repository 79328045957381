<template>
  <div>
    <v-app-bar dark fixed app :color="systemDetails.themecolor">
      <v-app-bar-nav-icon>
        <v-btn icon @click="$router.go(-1)"><v-icon :color="systemDetails.textcolor">mdi-arrow-left</v-icon></v-btn>
      </v-app-bar-nav-icon>
      <v-app-bar-title :style="`color: ${systemDetails.textcolor}`">{{ title }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn :color="$vuetify.theme.dark ? '' : systemDetails.textcolor" text @click="saveUpdateHandler" :loading="loader" class="justify-end">
        <!-- {{ ticketObj.id ? this.$t('update') : this.$t('save') }} -->
        <v-icon size="20">mdi-checkbox-marked-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <form-template :references.sync="formReferences" :model="ticketObj" ref="ticketReference">
      <template slot="extraDetails">
         <v-container class="grid-list-xl" style="padding:0px !important" v-if="modulesRelated.length > 0">
          <v-layout wrap class="pl-4 mb-1">
          <!--Module related-->
          <template v-for="(item, index) in modulesRelated">
            <v-flex lg3 :key="index" class="pa-2 pl-4 pr-7">
              <v-autocomplete  outlined dense hide-details :label="$t(item.label)" v-model="item.selectedValue" v-if="item.name === PROJECT" @keyup="searchProjects($event.target.value)"
                :loading="projectLoader" :items="listOfProjects" item-text="data.name" item-value="_id" class="mb-0">
              </v-autocomplete>
              <v-autocomplete   outlined dense hide-details :label="$t(item.label)" v-model="item.selectedValue" v-else
                :items="item.listOfValues" item-text="data.name" item-value="_id" class="mb-0">
              </v-autocomplete>
            </v-flex>
          </template>
          <!--end-->
          <v-flex lg12 v-if="$route.query && !!$route.query.convertticket">
            <v-checkbox v-model="ticketObj.deleteparenttask" :label="$t('taskDeleteConfirm')" class="pt-2"></v-checkbox>
          </v-flex>
          <v-flex lg12 v-if="$route.query && !!$route.query.converttask">
            <v-checkbox v-model="ticketObj.deleteparentticket" :label="$t('ticketDeleteConfirm')" class="pt-2"></v-checkbox>
          </v-flex>
          </v-layout>
        </v-container>
        <v-container>
          <template>
            <v-flex lg6>
              <v-text-field label="Attachments" @click='pickFile' prepend-icon='mdi-paperclip' dense outlined hide-details></v-text-field>
              <input type="file" style="display: none" id="attachments" ref="attachments" @change="onFilePicked" multiple>
            </v-flex>
            <v-flex lg6></v-flex>
            <v-row class="mt-0">
              <v-col v-for="(image, index) in listOfFiles" :key="`image_${index}`" cols="6" lg="1" md="3" sm="4">
                <v-hover v-slot="{ hover }">
                  <v-img :src="image" height="80" :aspect-ratio="16/9" width="200" contain>
                  <v-expand-transition>
                    <div v-if="hover"  class="transition-fast-in-fast-out primary darken-2 v-card--reveal display-3 white--text text-center" style="height: 100%;">
                      <v-icon @click="removeAttachment(index, 'listOfFiles')" dark>mdi-close-circle-outline</v-icon>
                    </div>
                  </v-expand-transition>
                  </v-img>
                </v-hover>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="6" v-for="(files, index) in listOfOtherFiles" :key="`file_${index}`">
                <v-card outlined height="50">
                  <v-row class="align-center">
                    <v-col cols="1" lg="1" sm="2" md="2">
                      <v-icon class="mr-4 ml-2" :color="files.color">{{ files.icon }}</v-icon>
                    </v-col>
                    <v-col class="d-flex align-center" cols="9" lg="9" sm="8" md="8">
                      <span class="pl-3 caption">{{ files.name }}</span><br><br>
                    </v-col>
                    <v-col cols="2" lg="2" sm="2" md="2">
                      <v-icon color="error" @click="removeAttachment(index, 'listOfOtherFiles')">mdi-close-circle-outline</v-icon>
                    </v-col>
                  </v-row>
              </v-card>
              </v-col>
            </v-row>
          </template>
          <v-layout wrap>
          <v-flex lg12 xs12 class="mt-5">
            <span v-if="type">{{$t('description')}}</span>
            <span v-else>{{$t('body')}}</span>
            <div class="ticket-editor">
              <ckeditor :editor="editor" v-model="ticketObj.body" ref="editor" :key="render"></ckeditor>
              <!-- <app-editor :editor="editor" v-model="ticketObj.body" :config="editorConfig" ref="editor" :key="render"></app-editor> -->
            </div>
          </v-flex>
          </v-layout>
        </v-container>
      </template>
    </form-template>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import FormTemplate from '../../components/FormTemplate'
import Editor from '@/ckeditor'
import CommonFunctions from './mixin'
export default {
  mixins: [CommonFunctions],
  data () {
    return {
      editor: Editor,
      MODULE_URL: 'tickets',
      type: this.$route.query.type === 'task' || !!this.$route.query.converttask ? true : false,
      ticketObj: {
        body: `<br><div class="content"><iframe id="userSign" frameborder="0" width="100%"></iframe></div>`
      },
      loading: false,
      loader: false,
      emailRegex: /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
      listOfAccounts: [],
      isLoading: false,
      accountLoading: false,
      enableSearch: false,
      render: 0,
      modulesRelated: [],
      attachmentsList: [],
      attachmentsFiles: [],
      listOfFiles: [],
      listOfOtherFiles: [],
      imagesTypes: ['image/bmp', 'image/png', 'image/jpg', 'image/jpeg'],
      listOfProjects: [],
      projectLoader: false,
      title: this.$route.query.type === 'task' || !!this.$route.query.converttask ? this.$t('createTask') : this.$t('createTicket')
    }
  },
  components: {
    FormTemplate
  },
  computed: {
    ...mapGetters(['formType', 'getUsers', 'getListOfMailboxes', 'getListOfTicketPriorities', 'getListOfTicketStatus', 'getListOfTicketCategory', 'getsignalRConnection', 'getListOfGroups', 'listOfModules', 'userDetails']),
  //  ...mapGetters(['getListOfTicketPriorities', 'getListOfTicketStatus', 'getListOfTicketType' ,'getUsers', 'getListOfMailboxes', 'getsignalRConnection', 'getuserDetails']),
    formReferences () {
      let item = {
        // title: this.$route.query.type === 'task' || !!this.$route.query.converttask ? this.$t('createTask') : this.$t('createTicket'),
        properties: [{
          model: 'subject',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('subject'),
          class: 'lg4 sm4 xs12'
        }, {
          model: 'to',
          type: this.formType.COMBOBOX,
          rules: this.$_multiSelectValidation,
          items: [],
          class: 'lg4 sm4 xs12',
          label: this.$t('to'),
          is_visible: !this.type
        }, {
          model: 'cc',
          type: this.formType.COMBOBOX,
          items: [],
          class: 'lg4 sm4 xs12',
          label: this.$t('cc'),
          is_visible: !this.type
        }, {
          model: 'bcc',
          type: this.formType.COMBOBOX,
          items: [],
          class: 'lg4 sm4 xs12',
          label: this.$t('bcc'),
          is_visible: !this.type
        }, {
          model: 'account_id',
          live_search_model: 'accountSearch',
          type: this.formType.AUTO_COMPLETE_LIVE,
          items: this.listOfAccounts,
          select_text: 'data.name',
          select_value: '_id',
          class: 'lg3 sm4 xs12',
          label: this.$t('account'),
          loading: this.accountLoading,
          change: this.loadValues
        }, {
          model: 'assignedto',
          type: this.formType.AUTO_COMPLETE,
          items: this.getUsersAndGroups,
          rules: this.$_requiredValidation,
          select_text: 'name',
          select_value: 'alternateid',
          class: 'lg2 sm4 xs12',
          label: this.$t('assignedTo')
        }, {
          model: 'mailboxid',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfMailboxes,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          rules: this.$_requiredValidation,
          label: this.$t('mailbox'),
          is_visible: !this.type
        }, {
          model: 'status',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketStatus,
          rules: this.$_requiredValidation,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          label: this.$t('status')
        }, {
          model: 'priority',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketPriorities,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          label: this.$t('priority')
        }, {
          model: 'category_id',
          type: this.formType.AUTO_COMPLETE,
          items: this.getListOfTicketCategory,
          select_text: 'name',
          select_value: '_id',
          class: 'lg2 sm4 xs12',
          label: this.$t('category')
        } 
        // {
        //   model: 'tags',
        //   type: this.formType.COMBOBOX,
        //   rules: [],
        //   multiple: true,
        //   items: [],
        //   label: this.$t('tags'),
        //   class: 'lg3 sm4 xs12 pt-2'
        // }
        ]
        // buttons: [{
        //   name: 'action_handler',
        //   color: 'success',
        //   label: this.ticketObj.id ? this.$t('update') : this.$t('save'),
        //   click: this.saveUpdateHandler,
        //   loading: this.loader,
        //   is_show: true
        // }, {
        //   name: 'close',
        //   color: 'info',
        //   // eslint-disable-next-line
        //   click: () => this.$router.push(`/${this.MODULE_URL}`),
        //   label: this.$t('backToList'),
        //   is_show: true
        // }]
      }
      return item
    },
    getUsersAndGroups () {
      let userList = this.$formatter.cloneVariable(this.getUsers)
      let groupList = this.$formatter.cloneVariable(this.getListOfGroups)
      return [ ...userList, ...groupList ]
    }
  },
  mounted () {
    let modulesRelated = this.listOfModules.filter(x => x.include_ticket && x.name !== 'Account' && x.isactive)
    this.modulesRelated = modulesRelated
    if (this.$route.query && (!!this.$route.query.converttask || !!this.$route.query.convertticket)) {
      this.enableSearch = false
      let query = this.$route.query
      let ticketInfo = JSON.parse(window.localStorage.getItem('taskOrTicketInfo'))
      if (ticketInfo) {
        if (ticketInfo.mail_info && ticketInfo.mail_info.length > 0) {
          ticketInfo.body = `${ticketInfo.mail_info[0].body} ${this.ticketObj.body}`
          ticketInfo.subject = ticketInfo.mail_info[0].subject
        }
        this.ticketObj = {
          ...ticketInfo,
          is_task : false,
          parenttaskid: query.convertticket ? ticketInfo._id : null,
          deleteparenttask: query.convertticket ? false : false,
          parentticketid: query.converttask ? ticketInfo._id : null,
          deleteparentticket: query.converttask ? false : false,
          cc: '',
          bcc: '',
          from: '',
          _id: null,
          mailboxid: null
        }
        this.loadValues() // Load realted fields values filtered by account
        this.listOfAccounts = ticketInfo.account_info
        // if (ticketInfo && ticketInfo.modulerelations && ticketInfo.modulerelations.length > 0)
        this.modulesRelated.forEach(element => { // Set Related module values
          let result = ticketInfo.modulerelations.find(x => x.module === element.name)
          if (result) element.selectedValue = result._id
        })
        this.render++
      }
    }
    setTimeout(() => { this.enableSearch = true })
    this.$store.dispatch('getListOfGroups')
    this.$store.dispatch('getUsers')
    this.$store.dispatch('getMailbox')
    this.$store.dispatch('getTicketStatus').then(() => {
      if (!this.ticketObj._id) {
        this.setDefaultValues({ prop: 'status', list: this.getListOfTicketStatus })
      }
    })
    this.$store.dispatch('getTicketPriority').then(() => {
      if (!this.ticketObj._id) {
        this.setDefaultValues({ prop: 'priority', list: this.getListOfTicketPriorities })
      }
    })
    this.$store.dispatch('getTicketCategory').then(() => {
      if (!this.ticketObj._id) {
        this.setDefaultValues({ prop: 'category_id', list: this.getListOfTicketCategory })
      }
    })
    if (this.$route.query.type !== 'task' && this.type !== 'task' ) {
      setTimeout(() => {
        let item = ''
        let currentUser = this.getUsers.find(x => x._id === this.userDetails._id)
        if (currentUser && currentUser.signature) {
          item = `${currentUser.signature}`
          document.getElementById("userSign").srcdoc = item
          setTimeout(() => {
            let element = document.getElementById('userSign')
            element.style.height = (element.contentWindow.document.all[2].offsetHeight + 30) + 'px'
            element.style.pointerEvents = 'none'
          }, 350)
        }
      }, 150)
    }
  },
  watch: {
    'ticketObj.accountSearch' (val) {
      if (val === undefined) return
      if (val && !val.includes('-') && this.enableSearch) this.loadAccountList(val)
    }
  },
  methods: {
    setDefaultValues (data) {
      let { list, prop } = data
      let item = list.find(x => x.isdefault)
      // let model = {}
      if (item) {
        this.ticketObj[prop] = item._id
        // model = Object.assign({ ...this.ticketObj }, { [prop]: item._id })
        // this.ticketObj = model
      }
    },
    supportingList (type, text) {
      text = this.$refs.ticketReference.$refs[text][0].lazySearch
      let model = { module: type, dialog: true, text }
      this.$store.commit('supportingList', model)
    },
    loadAccountList (searchTerm) {
      this.accountLoading = true
      this.listOfAccounts = []
      const model = {
        searchterm: searchTerm || ''
      }
      this.$api.execute('get', `moduledata/${this.ACCOUNT}/search?searchterm=${model.searchterm}`).then((response) => {
        this.listOfAccounts = response.data
      }).finally(() => {
        this.accountLoading = false
      })
    },
    async saveAttachmentsHandler () {
      var formData = new FormData()
      var filesList = this.attachmentsFiles
      if (filesList !== null && filesList.length > 0) {
        filesList.forEach(file => {
          formData.append(file.name, file)
        })
      }
      return new  Promise((resolve, reject) => {
        this.$api.execute('post', 'mailattachments/upload', formData)
          .then(({ data }) => {
            if (data && data.length) {
              let attachmentIds = data.map(x => x._id)
              resolve(attachmentIds)
            }
          })
      })
    },
    async saveUpdateHandler () {
      if (this.$refs.ticketReference.$refs.validateForm.validate()) {
        let ticketObj = this.$formatter.cloneVariable(this.ticketObj)
        ticketObj.body = ticketObj.body.replace('<div class="content"><iframe width="100%" frameborder="0" id="userSignFooter"> </iframe></div>', '')
        let to = this.ticketObj.to
        let cc = ticketObj.cc ? ticketObj.cc : []
        let bcc = this.ticketObj.bcc ? ticketObj.bcc : []
        if (!this.type) {
          let arrayOfEmails = [...to, ...cc, ...bcc]
          if (arrayOfEmails.length > 0) {
            for (let i = 0; i < arrayOfEmails.length; i++) {
              if (!this.emailRegex.test(arrayOfEmails[i])) {
                this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'invalidEmail' })
                return false
              }
            }
          }
        }
        this.loader = true
        if (this.listOfFiles.length > 0 || this.listOfOtherFiles.length > 0) ticketObj.attachments = await this.saveAttachmentsHandler()
        let url = `${this.MODULE_URL}/create`
        let model = ticketObj
        model.tags = JSON.stringify(model.tags)
        let assignedTo = []
        if (model.assignedto) {
          assignedTo = model.assignedto.split('_')
          if (assignedTo[0] === 'user') {
            model.assignedto = assignedTo[1]
            model.assignedto_group =  null
          } else {
            model.assignedto = null
            model.assignedto_group =  assignedTo[1]
          }
        }
        model.is_task = this.type ? true : false
        // If any module as ticket relation
        let modelRelatedModule = this.$formatter.cloneVariable(this.modulesRelated)
        model.modulerelations = []
        if (modelRelatedModule.length > 0) {
          model.modulerelations = modelRelatedModule.map(x => { return { id: x.selectedValue, module: x.name} })
        }
        this.$api.saveUpdateHandler(url, model).then(response => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: 'addedSucess' })
          this.$router.push(`/${this.MODULE_URL}/actions/${response._id}`)
          // window.localStorage.removeItem('taskOrTicketInfo')
          if (assignedTo.length > 0 && this.userDetails._id != model.assignedto[1]) {
            let  msg = ''
            if (assignedTo[0] === 'user') {
              msg = `${this.$t('assignedtoMessage')} ${response.number} ${this.$t('by')} ${this.userDetails.name}`
              this.getsignalRConnection.invoke('SendNotification', [assignedTo[1]], msg , response._id)
            } else {
              // let item = this.getListOfGroups.find(x => x.id == assignedTo[1])
              // let usersarray = []
              // let query = { filter: `groupid eq ${assignedTo[1]} and isactive eq 1` }
              // msg = `${item.name} ${this.$t('assignedFor')} ${response.number} ${this.$t('by')} ${this.userDetails.name}`
              // this.$api.execute('get', `usergroups/get_by_group/${assignedTo[1]}`).then(result => {
              //   usersarray = result.data.map(x => x.user_id)
              // }).finally(() => {
              //   this.getsignalRConnection.invoke('SendNotification', usersarray, msg, response._id)
              // })
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
    saveAirwayBill (id, model) {
       let airwayBills = {
          ids: model
        }
      this.$api.saveUpdateHandler(`airwaybills/update_bills/${id}`, airwayBills)
    },
    async onFilePicked (e) {
      const files = e.target.files
      for (let i = 0; i < files.length; i++) {
        if (files[i]) {
          this.attachmentsList.push(files[i].name)
          this.attachmentsFiles.push(files[i])
          if (this.imagesTypes.includes(files[i].type)) {
            let base64 = await this.toBase64(files[i])
            this.listOfFiles.push(base64)
          } else {
            let iconObj = this.getMimeTypeIcons(files[i].type)
            this.listOfOtherFiles.push({ name: files[i].name, type: files[i].type, ...iconObj })
          }
        }
      }
    },
    pickFile () {
      this.$refs.attachments.click()
    },
    loadValues () {
      this.modulesRelated.forEach((element, index) => {
        this.modulesRelated[index].listOfValues = []
        if (element.name === this.PROJECT) this.getProjectById(this.ticketObj)
        else if (this.ticketObj.account_id) {
          element.selectedValue = ''
          this.getRelatedList(element.name, index)
        } 
      })
    },
    async getRelatedList (name, index) {
      await this.$api.execute('get', `moduledata/${name}/search?relation_module=${this.ACCOUNT}&relation_id=${this.ticketObj.account_id}`).then(async response => {
        const result = await response.data
        if (name === this.CONTACT || name === this.PROJECT) {
          result.forEach(item => {
            item.data.name = name === this.CONTACT ? `${item.data.name || ''} ${item.data.last_name || ''}` : `${item.data.number || ''} ${item.data.name || ''}`
          })
        }
        this.$set(this.modulesRelated, index, { ...this.modulesRelated[index], listOfValues: result})
      })
    },
    removeAttachment (index, listname) {
      this[listname].splice(index, 1)
    }
  }
}
</script>
<style>
.combo .v-text-field .v-input__append-inner {
  display: none!important;
}
.ck-editor__editable_inline {
    min-height: 100px;
}
.ck-focused, .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border-color: #c4c4c4!important;
   box-shadow: 0 0 0px 1px #6cb5f9!important;
   border: #c4c4c4 !important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: #c4c4c4 !important;
}
</style>
